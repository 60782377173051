import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import styles from "./index.module.scss";

const NftGenerator = observer(() => {
    const [activeImageIndex, setActiveImageIndex] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setActiveImageIndex((activeImageIndex + 1) % 15)
        }, 100);
    }, [activeImageIndex]);

    return (
        <div className={styles["nft-container"]}>
            <img className={styles["nft-image"]}alt="NFT generator" src={"images/" + activeImageIndex + ".png"} />
        </div>
    )
});

export default NftGenerator;
