import { observer } from "mobx-react";
import React, { useState } from 'react';
import styles from "./index.module.scss";
import { useTranslation } from 'react-i18next';

const Faq = observer(() => {
    const { t } = useTranslation();
    const faqArray = t("faq.items", { returnObjects: true });
    const [faqsOpen, setFaqsOpen] = useState([]);

    const faqHandler = (i) => {
        if (faqsOpen.indexOf(i) === -1) {
            setFaqsOpen([...faqsOpen, i]);
        } else {
            setFaqsOpen(faqsOpen.filter(val => val !== i));
        }
    }

    return (
        <div id="faq" name="faq" className={styles["faq-container"]} data-aos="zoom-in">
            <h2>{t("faq.title")}</h2>
            <div className={styles["faq-wrapper"]} >
                {faqArray.map((item, i) => {
                    return (
                        <div key={i}>
                            <div className={styles["faq-title"]} onClick={() => faqHandler(i)}>
                                {item.question}
                            </div>
                            {faqsOpen.includes(i) &&
                                <div className={styles["faq-description"]}>
                                    {item.answer}
                                </div>}
                        </div>
                    )
                })}
            </div>
        </div>
    )
});

export default Faq;
