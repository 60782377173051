import { observer } from "mobx-react";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import NftGenerator from "../../components/nft-generator";
import { RELATIVE_PATH } from "../../constants/nl-config";
import { useStores } from "../../stores/RootStore";
import Button from "../button";
import RangeSlider from "../range-slider";
import styles from "./index.module.scss";

const PurchaseNfts = observer(() => {
	const { t } = useTranslation();
	const { uiStore, etherStore } = useStores();
	const { setShowMyNfts, isCorrectWallet, setModalOpen } = uiStore;
	const { connectToWallet, nftBalance, purchaseNft, walletAddress, sliderValue, walletBalance, soldNfts, maxNFTendos } = etherStore;
	const [tosCheckbox, setTosCheckbox] = useState(false);

	return (
		<div name="purchase-nfts" id="purchase-nfts" className={styles["generator-container"]}>
			<div data-aos="zoom-in" className={styles["generator-wrapper"]}>
				<h2>{t("purchase_nfts.purchase_nftendo")}</h2>
				<div className={styles["row"]}>
					<div className={styles["column"]}>
						<div className={styles["generator-image-container"]}>
							<NftGenerator />
						</div>
					</div>
					<div className={styles["column"]}>
						<div className={styles["generator"]}>
							<div className={styles["generator-title"]}>
								<h2>{soldNfts} / {maxNFTendos} {t("purchase_nfts.sold")}</h2>
							</div>
							{!walletAddress ?
								<></>
								:
								<h2>{t("purchase_nfts.balance")} {walletBalance} BNB</h2>
							}
							<RangeSlider />
							<label className={styles["checkbox-container"]}>
								<div className={styles["checkbox-text"]} onClick={() => setModalOpen(true)}>
									You must accept our <span className={styles["tos"]}> TOS </span> to purchase NFT:
								</div>
								<input
									className={styles["tos-checkbox"]}
									name="agreeTos"
									type="checkbox"
									checked={tosCheckbox}
									onChange={() => setTosCheckbox(!tosCheckbox)} />
								<span className={styles["checkmark"]}></span>
							</label>
							{!walletAddress ?
								<Button label={t("purchase_nfts.connect_to_wallet")} click={() => connectToWallet()}></Button>
								:
								<>
									{isCorrectWallet && tosCheckbox ?
										<>
											{soldNfts !== maxNFTendos &&
												<Button label={sliderValue > 1 ? "PURCHASE " + sliderValue + " NFTENDOS" : "PURCHASE " + sliderValue + " NFTENDO"} click={() => purchaseNft()}></Button>
											}
											{nftBalance.length > 0 &&
												<NavLink to={`${RELATIVE_PATH}/nftendos`}>
													<Button label={t("nft_search_bar.show_nfts")} click={() => setShowMyNfts(true)}></Button>
												</NavLink >}
										</>
										:
										!isCorrectWallet && tosCheckbox ?
											<Button label={t("purchase_nfts.wrong_chain_id")} click={() => connectToWallet()}></Button>
											:
											<Button label={t("purchase_nfts.accept_tos")} click={() => { setModalOpen(true); setTosCheckbox(true); }}></Button>
									}
								</>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
});

export default PurchaseNfts;
