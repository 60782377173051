import { observer } from "mobx-react";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const NftSearchBar = observer(() => {
    const { t } = useTranslation();
    const { uiStore, etherStore } = useStores();
    const { metadata, setMetadataSearchResult, searchNftendo, setSearchNftendo, showMyNfts, setShowMyNfts, isCorrectWallet } = uiStore;
    const { connectToWallet, walletAddress } = etherStore;
    const { soldNfts, nftBalance } = etherStore;

    const handleChange = (event) => {
        setSearchNftendo(event.target.value)
    }

    useEffect(() => {
        let currentSearchResult = [];
        if (searchNftendo === "" && !showMyNfts) {
            setMetadataSearchResult([]);
        } else if (!showMyNfts) {
            let nftIds = searchNftendo.replace(/\s+/g, '');
            let nftIdsArray = nftIds.split(",");
            nftIdsArray.forEach(nft => {
                nft = parseInt(nft);
                if (nft !== "" && nft < soldNfts && nftIdsArray[nftIdsArray.length - 1] !== "" && nft <= soldNfts) {
                    currentSearchResult.push(metadata[nft]);
                    setMetadataSearchResult(currentSearchResult)
                }
            })
        } else if (showMyNfts) {
            let currentSearchResult = [];
            nftBalance.forEach(nft => {
                nft = parseInt(nft);
                currentSearchResult.push(metadata[nft]);
                setMetadataSearchResult(currentSearchResult);
            })
        }
    }, [searchNftendo, setMetadataSearchResult, metadata, soldNfts, showMyNfts, nftBalance])


    return (
        <div className={styles["nft-search-bar-container"]}>
            <div className={styles["nft-search-form"]}>
                <label className={styles["nft-search-label"]}>{t("nft_search_bar.find_nftendos")}</label>
                <input disabled={showMyNfts} className={showMyNfts ? styles["nft-search-input-disabled"] : styles["nft-search-input"]} placeholder={t("nft_search_bar.placeholder")} type="text" value={searchNftendo} onChange={handleChange} />
                {!walletAddress ?
                    <button className={styles["nft-search-button"]} onClick={() => connectToWallet()}>
                        {t("purchase_nfts.connect_to_wallet")}
                    </button>
                    :
                    <>
                        {isCorrectWallet ?
                            <>
                                {!showMyNfts
                                    ?
                                    <button className={styles["nft-search-button"]} onClick={() => setShowMyNfts(!showMyNfts)}>
                                        {t("nft_search_bar.show_nfts")}
                                    </button>
                                    :
                                    <button className={styles["nft-search-button"]} onClick={() => setShowMyNfts(!showMyNfts)}>
                                        {t("nft_search_bar.search_nfts")}
                                    </button>
                                }
                            </>
                            :
                            <button className={styles["nft-search-button"]} onClick={() => connectToWallet()}>
                                {t("purchase_nfts.wrong_chain_id")}
                            </button>
                        }
                    </>
                }
            </div>
        </div>
    )
});

export default NftSearchBar;