import { observer } from "mobx-react";
import React, { useEffect } from 'react';
import Header from "../../components/header";
import Description from "../../components/nftendo-description";
import NftGenerator from "../../components/purchase-nfts";
import Faq from "../../components/faq";
import { useStores } from "../../stores/RootStore";
import { default as commonStyles } from "./index.module.scss";

const Home = observer(() => {
    const { uiStore } = useStores();
    const { setHomeMounted } = uiStore;

    useEffect(() => {
        setHomeMounted(true);
        return () => { setHomeMounted(false); };
    });

    return (
        <>
            <div className={commonStyles["container"]}>
                <Header />
                <Description />
                <NftGenerator />
                <Faq />
            </div>
        </>
    )
});

export default Home;