export const TRANSLATIONS_ZH = {
    navbar: {
        home: "首页",
        description: "关于我们",
        buy_nfts: "购买",
        faq: "常见问题",
        browse_nftendos: "浏览",
        menu: "菜单",
    },
    header: {
        title: "什么是NFTendos？",
        description: "10000个以编程方式生成的NFTendos已被创建，以居住在Binance智能链上。每个NFTendo都是对90年代和加密货币场景内社区所喜爱的人/备忘录的致敬。NFTendos还为社区和慈善机构提供潜在的奖励，如下图所示。",
        tv_text: ">>> 点击我 <<<"
    },
    nft_description: {
        reward_title: "社区奖励制度",
        reward_description: "为了给围绕NFTendo建立的社区创造一个激励机制，有一个奖励系统可以启动。如果NFTendos的总供应量（10000）在启动销售后的6个月内被售出，智能合约有一个内置功能，然后允许任何人从通过NFTendos销售收集的资金中启动一个随机奖励系统。1000个BNB被给予随机NFTendo ID的所有者，另外1000个BNB在一百个随机NFTendo ID和它们各自的所有者之间分配（每个10个BNB）。为了确保完全可验证的随机性和公平性，NFTendo已经与Chainlink合作，利用他们的VRF Oracle。总共300个BNB也被分配给了一个慈善机构，由社区投票决定。在投票中，每个地址持有的NFTendo都会获得一票。如果在开始销售后的6个月内，10000个NFT没有全部售出，那么这些资金将交给团队。任何在销售开始后6个月内留在合同上的无人认领的资金也将被重新分配给团队。",
        purchase_title: "我如何购买NFTendos？",
        purchase_description_1: "获得您自己的NFTendo的最简单的方法是在下面购买它。你也可以在任何支持NFTendos的NFT市场上交易它们。购买价格遵循粘合曲线，奖励早期的支持者，75%的NFT成本低于0.5 BNB，其余的成本最高为0.7 BNB。",
        purchase_description_1_prices:
            [
                "0 - 249: 保留给营销、帮助和社区奖励",
                "250 - 3499: 0.3 BNB",
                "3500 - 5499: 0.4 BNB",
                "5500 - 7499: 0.5 BNB",
                "7500 - 8999: 0.6 BNB",
                "9000 - 9999: 0.7 BNB"
            ],
        purchase_description_2: "要启动购买，你必须首先连接一个已经充值了BNB的钱包。一旦你完成了购买，NFT会自动发送到你的钱包，你可以在浏览标签下查看它。",
        interacting_title: "",
        interacting_description: "",
    },
    purchase_nfts: {
        purchase_nftendo: "购买NFTendos",
        sold: "出售",
        balance: "平衡:",
        connect_to_wallet: "连接到钱包",
        purchase_nftendos: "购买NFTENDOS",
        wrong_chain_id: "连接到错误的链条",
        accept_tos: "你必须接受我们的协议",
        disclamer: "通过购买NFTendos，您同意您只购买一个NFT。任何潜在的社区奖励都取决于NFTendos的总供应量在销售开始后的6个月内被卖完。如果NFTendos的总供应量在销售开始后的6个月内没有卖完，将不会有任何社区奖励。"
    },
    range_slider: {
        title: "要购买的NFTendos的数量",
        total_cost: "总费用"
    },
    nft_search_bar: {
        find_nftendos: "查找NFTendos:",
        placeholder: "用逗号隔开的ID，例如：: 1,2,3",
        show_nfts: "显示我的NFTS",
        search_nfts: "搜索NFTS"
    },
    faq: {
        title: "常问问题",
        items: [
            {question: "社区奖励系统是否完全随机化？", answer: "是的。我们与Chainlink和他们的VRF解决方案合作，该方案提供了防篡改的随机性，不能被任何用户、节点操作员或恶意行为者操纵，而使用block.time则可以被操纵。: https://chain.link/solutions/chainlink-vrf"},
            {question: "NFTendo的证明记录是什么？", answer: "每个NFTendo都使用SHA-256进行了散列，然后将所有散列组合成一个长字符串，再次使用SHA-256进行散列并存储在NFTendo智能合约内的区块链上。"},
            {question: "我的NFTendos的价值会增加吗？", answer: "NFTendos是Binance智能链上被设计为可收藏的交易卡的NFT。就像任何其他艺术品一样，价格完全取决于人们愿意支付什么，你最看重的卡片对其他人来说可能是零价值。就像购买任何艺术品一样，你无法知道价格是否会保持稳定、增加或价值为零。"},
            {question: "我能否提前知道我将得到哪种NFTendo？", answer: "在NFTendo被铸造之前，除了属于图像的SHA-256哈希值，所有的信息都是不公开的。在购买后，NFTendo存储库会在几分钟内自动更新相应的图像和统计信息。"},
            {question: "如果10000个NFTendos在6个月内没有全部售出会怎样？", answer: "如果在6个月内没有卖完所有的NFTendos，社区奖励将被取消，所有收集的资金将归团队所有。"},
            {question: "如果10000个NFTendos在6个月内全部售出，NFTendo会不会没有任何收获？", answer: "一旦社区奖励池（2000 BNB）被填满，NFTendo可以提取任何多余的资金。举例来说，如果合同上有2100个BNB，那么NFTendo在前6个月最多可以提取100个BNB。如果有1900个BNB，NFTendo在前6个月不能从合同中提取任何资金。如果在6个月内没有卖出所有10000个NFTendos，那么社区奖励系统就不成功，这意味着没有社区奖励被支付，NFTendo可以提取当前合同中的所有资金。"},
            {question: "我如何与我的NFTendos互动？", answer: "您可以在本网站和任何支持的钱包上查看您的NFTendos，它们可以在支持BEP721标准的开放式NFT市场上交易。"},
            {question: "我赢了! 我如何申请我的社区奖励？", answer: "如果社区奖励计划成功，你可以在销售开始后6个月内在网站上领取你的社区奖励。要领取您的社区奖励，请输入档期号码，并按下领取奖励的按钮。要成功领取，获奖者的地址和你的交易地址必须一致。"},
            {question: "如果在销售开始后的六个月内有无人认领的社区奖励，会发生什么？", answer: "从销售开始的6个月内，合同上任何未被认领的资金也将属于该团队。"},
            {question: "代码是什么？", answer: "上，上，下，下，左，右，左，右，B，A。"}
        ]
    }
};
