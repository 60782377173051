import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { DarkIcon } from "../../assets/img/darkIcon.js";
import { LightIcon } from "../../assets/img/lightIcon.js";
import { RELATIVE_PATH } from "../../constants/nl-config";
import ScrollLinkEnum from "../../enums/scroll-link-enum";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const NavigationBar = observer(() => {
    const { t, i18n } = useTranslation();
    const [languageMenu, setLanguageMenu] = useState(false);
    const [playMusic, setPlayMusic] = useState(false);
    const { uiStore } = useStores();
    const { switchTheme, theme, hamburgerMenuOpen, switchHamburgerMenu, scrollLink, setScrollLink, languageFlag, setLanguageFlag } = uiStore;

    const useMusic = () => {
        const audioElement = document.getElementsByClassName("audio-element")[0];
        if (!playMusic) {
            setPlayMusic(true);
            audioElement.volume = 0.3;
            audioElement.play();
        }
        else {
            setPlayMusic(false);
            audioElement.pause();
        }
    }

    useEffect(() => {
        switch (i18n.language) {
            case "en":
                setLanguageFlag("images/en.png");
                break;
            case "es":
                setLanguageFlag("images/es.png");
                break;
            case "fr":
                setLanguageFlag("images/fr.png");
                break;
            case "zh":
                setLanguageFlag("images/zh.png");
                break;
            default:
                setLanguageFlag("images/en.png");
        }
    }, [setLanguageFlag, i18n.language]);

    return (
        <>
            <div className={styles["navbar-container"]}>
                <div className={styles["navbar"]}>
                    <div className={styles["logo-container"]}>
                        <NavLink to="/">
                            <img alt={"NFTendo logo"} src={"images/nftendo-logo-pink.png"} />
                        </NavLink >
                    </div>
                    <div className={styles["links-container"]}>
                        <div className={styles["links"]}>
                            <NavLink onClick={() => setScrollLink(ScrollLinkEnum.HOME)} exact={true} activeClassName={scrollLink === ScrollLinkEnum.HOME ? styles["active-link"] : null} to="/">{t("navbar.home")}</NavLink >
                            <NavLink onClick={() => setScrollLink(ScrollLinkEnum.DESCRIPTION)} activeClassName={scrollLink === ScrollLinkEnum.DESCRIPTION ? styles["active-link"] : null} to="/#nftendo-description">{t("navbar.description")}</NavLink>
                            <NavLink onClick={() => setScrollLink(ScrollLinkEnum.BUY_NFTS)} activeClassName={scrollLink === ScrollLinkEnum.BUY_NFTS ? styles["active-link"] : null} to="/#purchase-nfts">{t("navbar.buy_nfts")}</NavLink>
                            <NavLink onClick={() => setScrollLink(ScrollLinkEnum.FAQ)} activeClassName={scrollLink === ScrollLinkEnum.FAQ ? styles["active-link"] : null} to="/#faq">{t("navbar.faq")}</NavLink>
                            <NavLink onClick={() => setScrollLink(ScrollLinkEnum.NFTENDOS)} activeClassName={styles["active-link"]} to={`${RELATIVE_PATH}/nftendos`}>{t("navbar.browse_nftendos")}</NavLink >
                            <img className={styles["casette"]} alt="Casette" src={"images/cassette.png"} onClick={() => useMusic()} />
                            <audio className="audio-element" onClick={() => setPlayMusic(!playMusic)} loop>
                                <source src="audio/8_bit_ooame_lofi.mp3"></source>
                            </audio>
                        </div>
                        <div className={styles["theme-wrapper"]}>
                            <div className={styles["hamburger-menu"]} onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); }}>
                                {t("navbar.menu")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles["dark-light-theme"]} onClick={() => { switchTheme(); }}>
                    <LightIcon theme={theme} />/<DarkIcon theme={theme} />
                </div>
                <a href="https://t.me/nftendo">
                    <img alt="Telegram icon" className={styles["telegram"]} src={theme === "light" ? "images/telegram-dark.png" : "images/telegram-light.png"}/>
                </a>
                <img alt="Language flag" className={styles["flag-wrapper"]} src={languageFlag} onClick={() => setLanguageMenu(!languageMenu)} />
            </div>
            {languageMenu ?
                <div className={styles["language-menu-dropdown"]}>
                    <img alt="English flag" className={styles["flag-wrapper"]} src={"images/en.png"} onClick={() => { i18n.changeLanguage("en"); setLanguageMenu(!languageMenu) }} />
                    <img alt="Spanish flag" className={styles["flag-wrapper"]} src={"images/es.png"} onClick={() => { i18n.changeLanguage("es"); setLanguageMenu(!languageMenu) }} />
                    <img alt="French flag" className={styles["flag-wrapper"]} src={"images/fr.png"} onClick={() => { i18n.changeLanguage("fr"); setLanguageMenu(!languageMenu) }} />
                    <img alt="Chinese flag" className={styles["flag-wrapper"]} src={"images/zh.png"} onClick={() => { i18n.changeLanguage("zh"); setLanguageMenu(!languageMenu) }} />
                </div>
                : null}
            {hamburgerMenuOpen ?
                <div className={styles["hamburger-menu-dropdown"]}>
                    <div className={styles["responsive-links-container"]}>
                        <NavLink onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); setScrollLink(ScrollLinkEnum.HOME) }} exact={true} activeClassName={scrollLink === ScrollLinkEnum.HOME ? styles["active-link"] : null} to="/">{t("navbar.home")}</NavLink >
                        <NavLink onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); setScrollLink(ScrollLinkEnum.DESCRIPTION); }} activeClassName={scrollLink === ScrollLinkEnum.DESCRIPTION ? styles["active-link"] : null} to="/#nftendo-description">{t("navbar.description")}</NavLink>
                        <NavLink onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); setScrollLink(ScrollLinkEnum.BUY_NFTS); }} activeClassName={scrollLink === ScrollLinkEnum.BUY_NFTS ? styles["active-link"] : null} to="/#purchase-nfts">{t("navbar.buy_nfts")}</NavLink>
                        <NavLink onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); setScrollLink(ScrollLinkEnum.FAQ); }} activeClassName={scrollLink === ScrollLinkEnum.FAQ ? styles["active-link"] : null} to="/#faq">{t("navbar.faq")}</NavLink>
                        <NavLink onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); setScrollLink(ScrollLinkEnum.NFTENDOS); }} activeClassName={styles["active-link"]} to={`${RELATIVE_PATH}/nftendos`}>{t("navbar.browse_nftendos")}</NavLink >
                        <div className={styles["dark-light-theme-mobile-wrapper"]}>
                            <div className={styles["dark-light-theme-mobile"]} onClick={() => { switchTheme(); }}>
                                <LightIcon theme={theme} />/<DarkIcon theme={theme} />
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
});

export default NavigationBar;
