export const TRANSLATIONS_EN = {
    navbar: {
        home: "HOME",
        description: "ABOUT",
        buy_nfts: "PURCHASE",
        faq: "FAQ",
        browse_nftendos: "BROWSE",
        menu: "MENU",
    },
    header: {
        title: "What are NFTendos?",
        description: "10000 programmatically generated NFTendos have been created to inhabit the Binance Smart Chain. Each NFTendo is a homage to the 90s and people/memes loved by the community within the cryptocurrency scene. NFTendos also offer potential rewards to the community and charities, as can be seen below.",
        tv_text: ">>> CLICK ME <<<"
    },
    nft_description: {
        reward_title: "Community reward system",
        reward_description: "To create an incentive for a community to be built around NFTendo, there is a reward system that can kick in. If the total supply of NFTendos (10000) have been sold within 6 months after the launch of sale, the smart contract has a built-in function that then allows anyone to initiate a randomized reward system from funds collected through the sale of NFTendos. 1000 BNB is given to the owner of a random NFTendo ID, and an additional 1000 BNB is split between one hundred random NFTendo IDs and their respective owners (10 BNB each). To ensure fully verifiable randomization and fairness of which IDs are selected as winners, NFTendo have worked with Chainlink to utilize their VRF Oracle. A total of 300 BNB have also been allocated to be given to a charity, as voted by the community. In the event that not all 10000 NFTs have been sold within 6 months from the start of sale, the funds are given to the team. Any unclaimed funds left on the contract 6 months from the start of sale will also be reallocated to the team.",
        purchase_title: "How do I purchase NFTendos?",
        purchase_description_1: "The easiest way to acquire your very own NFTendo is to purchase it below. You can also trade them on any NFT marketplaces supporting NFTendos. The purchase price follows a bonding curve which rewards early supporters, and 75% of the NFTs cost less than 0.5 BNB while the rest cost a maximum of 0.7 BNB.",
        purchase_description_1_prices:
            [
                "0 - 249: Reserved for marketing, help, and community rewards",
                "250 - 3499: 0.3 BNB",
                "3500 - 5499: 0.4 BNB",
                "5500 - 7499: 0.5 BNB",
                "7500 - 8999: 0.6 BNB",
                "9000 - 9999: 0.7 BNB"
            ],
        purchase_description_2: "To initiate the purchase you must first connect a wallet which has been topped up with BNB. Once you have completed the purchase, the NFT is automatically sent to your wallet and you can view it under the Browse tab.",
        interacting_title: "",
        interacting_description: "",
    },
    purchase_nfts: {
        purchase_nftendo: "Purchase NFTendos",
        sold: "SOLD",
        balance: "Balance:",
        connect_to_wallet: "CONNECT TO WALLET",
        purchase_nftendos: "PURCHASE NFTENDOS",
        wrong_chain_id: "CONNECTED TO WRONG CHAIN",
        accept_tos: "YOU MUST ACCEPT OUR TOS",
        disclaimer: "By purchasing NFTendos you agree that you are only purchasing an NFT. Any potential community rewards are dependant on the total supply of NFTendos having been sold out within 6 months from the start of sale. In the event that the total supply of NFTendos have not been sold out within 6 months from start of sale, no community rewards will be given out."
    },
    range_slider: {
        title: "Amount of NFTendos to purchase",
        total_cost: "Total cost"
    },
    nft_search_bar: {
        find_nftendos: "Find NFTendos:",
        placeholder: "IDs seperated with comma e.g.: 1,2,3",
        show_nfts: "SHOW MY NFTS",
        search_nfts: "SEARCH NFTS"
    },
    faq: {
        title: "FREQUENTLY ASKED QUESTIONS",
        items: [
            {question: "Is the community reward system fully randomized?", answer: "Yes. We have worked with Chainlink and their VRF solution which provides tamper-proof randomness that cannot be manipulated by any user, node operator, or malicious actor compared to for example using block.time which could be manipulated: https://chain.link/solutions/chainlink-vrf"},
            {question: "What is the provance record for NFTendo?", answer: "Each NFTendo has been hashed using SHA-256, and all hashes are then combined into one long string which is again hashed using SHA-256 and stored on the blockchain inside the NFTendo smart contract."},
            {question: "Will the value of my NFTendos increase?", answer: "NFTendos are NFTs designed as collectible trading cards on the Binance Smart Chain. Just as any other piece of art, the price fully depends what people are willing to pay, and the cards you value the highest may for others have zero value. Just as buying any piece of art, you can't know if the price will stay stable, increase of have a value of zero."},
            {question: "Can I, in advance, know which NFTendo I will get?", answer: "Before a NFTendo has been minted, all information except for the SHA-256 hash belonging to the image is unrevealed. After a purchase has been made, the NFTendo repository is automatically updated with the corresponding image and stats with a few minutes."},
            {question: "What happens if not all 10000 NFTendos have been sold within 6 months?", answer: "In the event that not all NFTendos have been sold within 6 months, the community reward is cancelled and all funds collected goes to the team."},
            {question: "Will NFTendo not get anything if all 10000 NFTendos have been sold within 6 months?", answer: "Once the community reward pool (2000 BNB) has been filled up, NFTendo can withdraw any excess funds. As an example, if there is 2100 BNB on the contract then NFTendo are able to withdraw a maximum of 100 BNB during the first 6 months. If there is 1900 BNB, NFTendo cannot withdraw any funds from the contract during the first 6 months. Should not all 10000 NFTendos have been sold within 6 months, then the community reward system has been unsuccessful which means no community rewards are paid out and NFTendo can withdraw all funds currently in the contract."},
            {question: "How do I interact with my NFTendos?", answer: "You can view your NFTendos on this website and on any wallet with support, and they are tradable on open NFT Marketplaces supporting the BEP721 standard."},
            {question: "I won! How do I claim my community reward?", answer: "You can claim your community reward on the website within 6 months from start of sale, if the community reward program was successful. To collect your community reward, input the slot number and press the Claim Reward button. To successfully claim, the winner address and the address you are transacting from must match."},
            {question: "What happens if there are unclaimed community rewards after six months from start of sale?", answer: "Any unclaimed funds left on the contract 6 months from the start of sale will also belong to the team."},
            {question: "What is the code?", answer: "Up, Up, Down, Down, Left, Right, Left, Right, B, A."}



        ]
    }
};
