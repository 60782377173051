import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Konami from 'react-konami-code';
import { Link } from 'react-router-dom';
import Snake from 'react-simple-snake';
import Button from "../../components/button";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Header = observer(() => {
    const [snakeAppear, setSnakeAppear] = useState(false);
    const { t } = useTranslation();
    const [tvTextShown, setTvTextShown] = useState(false);
    const [imageCounter, setImageCounter] = useState(0);
    const [animatedImage, setAnimatedImage] = useState("images/nftendo-logo-tv.png");
    const { uiStore } = useStores();
    const { theme } = uiStore;
    let animatedNft = "";

    const easterEgg = () => {
        setSnakeAppear(true);
    }

    const getAnimatedImage = () => {
        if (imageCounter < 15) {
            animatedNft = "images/222x158/" + imageCounter + "-158.png";
            setImageCounter(imageCounter + 1)
        } else {
            setImageCounter(0);
            animatedNft = "images/222x158/" + imageCounter + "-158.png";
        }
        return animatedNft;
    }

    useEffect(() => {
        if (!animatedNft) {
            setTimeout(() => {
                setTvTextShown(true)
            }, 5000);
        }
    }, [animatedNft])

    return (
        <div id="header" className={styles["header-container"]}>
            <div data-aos="zoom-in">
                <div name="tv-layer">
                    <h2>{t("header.title")}</h2>
                    <div>
                        <div >
                            <div>
                                <p>{t("header.description")}</p>
                            </div>
                        </div>
                        <Konami action={easterEgg} />
                        <div>
                            <div className={styles["tv-container"]}>
                                <div className={styles["tv-wrapper"]}>
                                    <img alt="Tv background" className={styles["tv-background"]} src={theme === "light" ? "images/tv-light.png" : "images/tv-dark.png"} />
                                    <img alt="Tv" className={styles["tv"]} src={"images/tv.png"} onClick={() => { setAnimatedImage(getAnimatedImage()); setTvTextShown(false) }} />
                                    <img alt="Tv NFT" className={styles["tv-image-animation"]} src={animatedImage} />
                                    {snakeAppear ?
                                        <div className={styles["snake-container"]}>
                                            <Snake snakeColor="#6700ff" percentageWidth="100" />
                                        </div>
                                        :
                                        null}
                                    {tvTextShown ?
                                        <div className={styles["tv-text"]}>
                                            {t("header.tv_text")}
                                        </div>
                                        :
                                        null}
                                </div>
                            </div>
                        </div>
                        <Link to="/#purchase-nfts">
                            <Button label={"BUY NOW"} />
                        </Link>

                    </div>
                </div>
            </div>
        </div>
    )
});

export default Header;
