import { observer } from "mobx-react";
import React from 'react';
import styles from "./index.module.scss";
import { useTranslation } from 'react-i18next';

const NftendoDescription = observer(() => {
    const { t } = useTranslation();
    const priceArray = t("nft_description.purchase_description_1_prices", { returnObjects: true });

    return (
        <div id="nftendo-description" name="nftendo-description" className={styles["description-row"]}>
            <div className={styles["description-container"]}>
                <div data-aos="zoom-in">
                    <h2>{t("nft_description.reward_title")}</h2>
                    <div className={styles["column"]}>
                        <p>{t("nft_description.reward_description")}</p>
                    </div>
                    <div className={styles["column-image"]}>
                        <img alt="Funds are safu" src="images/reward-description.png"></img>
                        <img alt="Bubble" className={styles["bubble-gif"]} src="images/bubble.gif"></img>
                    </div>
                </div>
                <div data-aos="zoom-in">
                    <h2>{t("nft_description.purchase_title")}</h2>
                    <p>{t("nft_description.purchase_description_1")}</p>
                    <ul>
                        {priceArray.map((item, i) => {
                            return <li key={i}>#{item}</li>
                        })}
                    </ul>
                    <p>{t("nft_description.purchase_description_2")}</p>
                </div>
                <div data-aos="zoom-in">
                    <h2>{t("nft_description.interacting_title")}</h2>
                    <p>{t("nft_description.interacting_description")}</p>
                </div>
            </div>
        </div>
    )
});

export default NftendoDescription;
