import { observer } from "mobx-react";
import Nfts from "../../components/nfts";
import { default as commonStyles } from "./index.module.scss";

const Nftendos = observer(() => {

    return (
        <>
            <div className={commonStyles["container"]}>
                <Nfts />
            </div>
        </>

    )
});

export default Nftendos;