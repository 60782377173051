import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./index.module.scss";
import { useStores } from "../../stores/RootStore";
import configData from "../../constants/config-data.json"

const Nfts = observer(() => {
    const [cards, setCards] = useState([]);
    const { uiStore, etherStore } = useStores();
    const { metadata, setMetadata, metadataSearchResult, setIsLoading, searchNftendo, showMyNfts } = uiStore;
    const { soldNfts } = etherStore;
    const fetchSize = 9;

    const fetchMetadata = useCallback(() => {
        return fetch(configData.revealedNfts)
            .then(response => response.json())
            .then((jsonData) => {
                setMetadata(jsonData);
                setIsLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }, [setMetadata, setIsLoading])

    const fetchMoreData = () => {
        if (cards.length - 1 + fetchSize > soldNfts) {
            setCards([...cards, ...metadata.slice(cards.length, cards.length + soldNfts)])
        } else {
            setCards([...cards, ...metadata.slice(cards.length, cards.length + fetchSize)])
        }
    };

    useEffect(() => {
        if (cards.length === 0) {
            setIsLoading(true);
            fetchMetadata()
                .then(() => {
                    setCards(metadata.slice(0, fetchSize));
                })
        }
    }, [cards.length, fetchMetadata, metadata, setIsLoading, showMyNfts])

    return (
        <div data-aos="zoom-in" className={styles["nft-container"]}>
            {cards.length > 0 && (searchNftendo === "" && !showMyNfts) ?
                <InfiniteScroll style={{ overflow: "hidden" }} className={styles["infinite-scroll"]}
                    dataLength={cards.length}
                    next={fetchMoreData}
                    hasMore={cards.length < soldNfts}
                >
                    {cards.map((card, index) => (
                        <div key={index} className={styles["flip-card"]}>
                            <div className={styles["flip-card-inner"]}>
                                <div className={styles["flip-card-front"]}>
                                    <img src={card.image} alt="Avatar" />
                                </div>
                                <div className={styles["flip-card-back"]}>
                                    <h2>{card.name}</h2>
                                </div>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
                :
                <>
                    {((cards.length > 0 && soldNfts) || (cards.length > 0 && metadataSearchResult.length > 0)) &&
                        <div className={styles["ntf-search-result-wrapper"]}>
                            {metadataSearchResult.map((card, index) => (
                                <div key={index} className={styles["flip-card"]}>
                                    <div className={styles["flip-card-inner"]}>
                                        <div className={styles["flip-card-front"]}>
                                            <img src={card.image} alt="Avatar" />
                                        </div>
                                        <div className={styles["flip-card-back"]}>
                                            <h2>{card.name}</h2>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </>
            }
        </div >
    )
});


export default Nfts;
